<template>
    <div class="about">
        {{ $t('help.text') }}
    </div>
</template>

<script>
    export default {
        name: "Help"
    }
</script>

<style scoped>
    .about{
        margin: auto;
        width:900px;
        background: white;
        padding:20px;
    }
</style>