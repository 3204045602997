<template>
  <div class="about">
    <h1>This is an about page</h1>

    prihlasen: {{ me.email}}
  </div>
</template>
<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'



export default {
  name: 'about',
  components: {
   // HelloWorld
  },
  mounted(){

    console.log('mounted about')
  },
   computed: {
      counter () {
        return this.$store.geters.counter
      },
      me(){
           return this.$store.getters.me
      }

    }



}
</script>
