<template>
    <div class="QrPrint">
        <qrcode :value="kod" :options="{ width: 300, errorCorrectionLevel:'L' }"></qrcode>
    </div>
</template>

<script>

import VueQrcode from '@chenfengyuan/vue-qrcode';
import Vue from 'vue'
Vue.component(VueQrcode.name, VueQrcode);

export default {
name: "QrDirectPrint",

  data(){
    return {
    kod:''
   }
  },
  mounted() {
     //this.$route.params.id

    this.kod=this.$route.query.qr
  }
}
</script>

<style scoped>
    canvas{
        margin:auto;
        display: block;
    }
</style>
