<template>
    <div class="QrPrint">

        <qrcode :value="kod" :options="{ width: 300, errorCorrectionLevel:'L' }" v-if="loaded"></qrcode>
        <p v-else>loading...</p>

    </div>
</template>

<script>
    import VueQrcode from '@chenfengyuan/vue-qrcode';
    import Vue from 'vue'
    Vue.component(VueQrcode.name, VueQrcode);
    const koder = require('../../koder.js')
    const axios = require("axios");
    axios.defaults.withCredentials = true;
    export default {
        props: {
        },
        methods:{


            loadMotor(){
                console.log('load m', this.motorId)
                axios.get(process.env.VUE_APP_API_ROOT_URL + "/motors/"+this.motorId+"/?t="+ new Date().getTime() ).then(result => {
                    console.log("status motor", result)
                    if (result.status == 200) {
                        this.motor = result.data
                        this.$emit('motorUpdated', this.motor)
                        this.kod = koder(this.motor)
                        this.loaded=true
                    }
                }).catch(e =>{
                    console.error('errrr', e)
                })
            },


        },
        data(){
          return {
              motor:[],
              kod:'CAG',
              motorId:0,
              loaded:false
          }
        },
         mounted() {
            this.motorId = this.$route.params.id
            this.loadMotor()
        },
    };
</script>

<style>
    canvas{
        margin:auto;
        display: block;
    }
</style>
